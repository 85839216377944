<template>
  <div class="d-flex justify-center align-center full_h bg_main">
    <div>
      <div class="d-flex justify-center mb-5">
        <img src="../../assets/img/logo-sikelim-2.png" height="200px" alt="" />
      </div>
      <div>
        <h3 class="text-center">
          Sorry, the page not found or under development
        </h3>
      </div>
      <h1 class="text-center">404</h1>
      <div class="d-flex justify-center">
        <v-btn color="#00b4cc" dark class="text-capitalize" to="/"
          ><v-icon>mdi-home</v-icon> Take me home</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>